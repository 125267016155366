import React, { useContext, useEffect } from 'react';
import { getNumPageDocs } from './util/site.js';
import { getDocName } from './util/document.js';
import {WispContext} from './WispProvider';
import {WispButton} from './components/WispButton';
import {WispInput} from './components/WispInput';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchGet, getWispUrl, wisp_urls } from './util/fetch.js';
import $ from 'jquery';

export const TableNav = (props) => {
    var wispData = useContext(WispContext);
    const [profile, setProfile] = React.useState(null);
	const [rating, setRating] = React.useState(0);
	const [growth, setGrowth] = React.useState(0);

    // useEffect(() => {
	// 	// if (ticker) {
	// 	//     setBusy(true); // Start busy state before fetch
	// 	//     var url = wisp_urls.stock_get_profile;
	// 	//     var token = props.token;
	// 	//     var headers = {
	// 	//         'Authorization': 'Bearer ' + token,
	// 	//         'Ticker': ticker,
	// 	//         'user-id': props.userId,
	// 	//     };

	// 	//     fetch(url, { method: 'GET', headers: headers })
	// 	//         .then(response => response.json())
	// 	//         .then(data => {
	// 	//             setProfile(data);
	// 	//             setBusy(false); // End busy state after fetch completes
	// 	//         })
	// 	//         .catch(error => {
	// 	//             console.error('Error:', error);
	// 	//             setBusy(false); // End busy state even if there's an error
	// 	//         });
	// 	// }
    // }, [ticker]);

	const handleRatingChange = (value) => {
        setRating(value); // Set the inputValue state to whatever is typed in WispInput
    };

	const handleGrowthChange = (value) => {
        setGrowth(value); // Set the inputValue state to whatever is typed in WispInput
	};

	function saveCustomVals() {
		var url = wisp_urls.stock_save_custom;
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'site-id': props.site.id,
            'user-id': props.userId,
        }
        const stockData = {
            'ticker': props.ticker,
			'rating': rating,
			'growth': growth,
        };
        fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(stockData) })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                console.log('success = ' + data);
				props.hideFN();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
	}

	var stl = {};
	if (props.top) {
		stl.top = props.top;
		stl.position = 'absolute';
	} else {
		stl.position = 'fixed';
	}

	// console.log('safeArea = ' + wispData.safeArea);
	if (wispData.safeArea) {
		stl.top = (parseInt(wispData.safeArea.top) + 50) + 'px';
	}

	//  style={{maxHeight:maxH,overflow:'hidden'}}
    return (
		<div className='nav_panel_fixed' style={stl}>
            <div id='main_pan_scroll_cont' className='navHeader'>
                <div id='main_pan_scroll_icont'>
                    {props.ticker}
					<FontAwesomeIcon className='fa-2x closeClass' icon={faTimes} onClick={() => props.hideFN()}/>
                    {profile}
                </div>
        	</div>
			<div className='stockValueCont'>
				<WispInput
					id='ratingInput'
					hint='Management Rating'
					initValue={props.tickerValues[4]}
					inputStyle={{paddingTop: '7px'}}
					classes='active stockValueInput'
					ti='1' 
					onChange={handleRatingChange} />
				<WispInput
					id='growthInput'
					hint='Growth Estimate'
					initValue={props.tickerValues[5]}
					inputStyle={{paddingTop: '7px'}}
					classes='active stockValueInput'
					ti='1' 
					onChange={handleGrowthChange} />
				<WispButton
					bcls='primaryButton'
					d='inline-block'
					width="100%"
					js={() => saveCustomVals()}
					m='20px 0px 0px 0px'
					mes='Save Changes' />
			</div>
        </div>
    );
}

//     return "<div className='wisp_but webt_ui_color_1 " + bcls + "' style={{display:" + d + ";padding:8px;font-size:16px;width:" + w + "px;text-align: center; cursor: pointer;margin:" + m + ";' onClick={" + js + "'>" + mes + "</div>
