import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from './WispProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle as fasCircle, faExclamation, faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WispCheckBox } from './components/WispCheckBox';
import { WispButton } from './components/WispButton';
import { WispSelect } from './components/WispSelect.js';
import { fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';

// controls to publish a site
export const TableSettings = (props) => {

	const [forceUpdate, setForceUpdate] = React.useState(0);

	var wispData = useContext(WispContext);

	useEffect(() => {


	}, []);

	function toggleMakeAvailable() {
		if (props.site.state !== 'published') {
			props.site.state = 'published';
		} else {
			props.site.state = 'created';
		}
		var token = wispData.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_edit, props.site.id);
		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(props.site) })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				setForceUpdate(forceUpdate + 1)
			})
			.catch((error) => {
				console.error('Error:', error);
			});

	}

	var statIcon;
	var checkDiv;
	if (!wispData.mobileView) {
		if (props.site.state === 'published') {
			checkDiv = (
				<div className='set_sect_stat' ><FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} /></div>
			);
		} else {
			// statIcon = (<FontAwesomeIcon icon={faCheck} style={{ color: '#06a506' }} />);
			//var statStyle = {};
			// var checked = true;
			/* if (props.site.agreed === false) {
				statStyle = { background: '#eaeaea' };
				statIcon = (<FontAwesomeIcon icon={faExclamation} style={{ color: '#ffff1e' }} />);
				checked = false;
			} */
			checkDiv = (
				<div className='set_sect_stat' style={{background: '#eaeaea'}}><FontAwesomeIcon icon={faExclamation} style={{ color: '#ffff1e' }} /></div>
			);

		}
	}
	var makeAvailable = false;
	if (props.site.state === 'published') {
		makeAvailable = true;
	}

	return (
		<div className='siteEditSection'>
			<div className='settings_heading'>
				<span>Custom Portfolio Columns</span>
			</div>
			<div className='wisp_app_list'>
				{/* <WispSelect
					val='PV-10'
					nOptions={1}
					options={['PV-10']}
					display='inline-table'
					w='130'
				/> */}
                <WispButton
                    bcls='primaryButton'
                    d='inline-block'
                    width="220px"
                    js={() => props.fn1()}
                    m='0px'
                    mes='Add Column' />
			</div>
		</div >
	);


}

