import { getCookie } from './base.js';

var fetch_results = {};
var fetch_token;

export function fetchClear(url) {
    fetch_results[url] = null;
}

function waitForResult(i, url, resultFN, arg1) {         //  create a loop function
    setTimeout(function() {   //  call a 3s setTimeout when the loop is called
        if (fetch_results[url] === 'fetching') {
            i++;                    //  increment the counter
            if (i < 10) {           //  if the counter < 10, call the loop function
                waitForResult(i, url, resultFN, arg1);             //  ..  again which will trigger another 
            }                       //  ..  setTimeout()
        } else {
            resultFN(true, fetch_results[url], arg1);
        }
    }, 1000)
}

export function fetchGet(url, resultFN, wispData, forceUpdate, arg1, header, responseFormat, arg2) {
    // console.log('fetch ' + url);
	if (typeof fetch_results[url] !== 'undefined' && forceUpdate !== true) {
        if (fetch_results[url] === 'fetching') {
            waitForResult(1, url, resultFN, arg1);
        } else if (resultFN) {
    		resultFN(true, fetch_results[url], arg1);
        }
		return {};
    }
    var headers = {
//        'Content-Type': 'application/json',
    };
    if (wispData.token && wispData.publicState.wispUserId) {
    	headers['user-id'] = wispData.publicState.wispUserId;
        headers['Authorization'] = 'Bearer ' + wispData.token;
    }

    // add header values provided to this function
    if (header) {
        headers = [headers, header].reduce(function (r, o) {
            Object.keys(o).forEach(function (k) { r[k] = o[k]; });
            return r;
        }, {});
    }

    fetch_results[url] = "fetching";
    fetch(url, {method: 'GET', mode: 'cors', headers})
    .then(function(response) {
		if (!responseFormat || responseFormat === 'json') {
			return response.json();
		}
        return response.text();
    })
    .then(data => {
        fetch_results[url] = data;
        resultFN(true, data, arg1, arg2);
    })
    .catch((error) => {
        var i = url;
        resultFN(false, error);
    });
    return {};
}

export function fetchPost(url, resultFN) {

    fetch_token = window.localStorage.getItem("wisp_token"); // getCookie('wisp_token');
    if (process.env.REACT_APP_SUBDOMAINS === 'true') {
        fetch_token = getCookie('wisp_token'); // can't use localstorage when doing subdomain prefixes
    }
    var headers = {
//        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + fetch_token,
    };

    fetch(url, {method: 'POST', mode: 'cors', headers})
    .then(function(response) {
        // console.log('response.status = ' + response.status); // Will show you the status
        return response.json();
    })
    .then(data => {
        if (resultFN) {
            resultFN(true, data);
        }
    })
    .catch((error) => {
        if (resultFN) {
            resultFN(false, error);
        }
        console.error('Error:', error);
    });
    return {};
}

//
export const wisp_urls = {
	app_sites_get: process.env.REACT_APP_WISP_URL + "/app/{app_id}/sites/",
    azure_oauth: process.env.REACT_APP_WISP_URL + "/azure/oauth/",
	azure_add_file: process.env.REACT_APP_WISP_URL + "/azure/add_file",
    charge_create: process.env.REACT_APP_WISP_URL + "/charge/",
	docmap_edit: process.env.REACT_APP_WISP_URL + "/docboxmap/{docboxmap_id}/",
	docmap_reorder: process.env.REACT_APP_WISP_URL + "/docboxmap/{docboxmap_id}/reorder/",
    document_create: process.env.REACT_APP_WISP_URL + "/document/",
    // document_create_file: process.env.REACT_APP_WISP_URL + "/document/file/",
	document_delete: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/delete/",
	document_get: process.env.REACT_APP_WISP_URL + "/document/{doc_id}",
    document_get_tickers: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/get_tickers",
	document_position: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/position/",
	document_replace: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/replace/",
	document_rotate: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/rotate/",
	document_update: process.env.REACT_APP_WISP_URL + "/document/{doc_id}/update/",
    elliots_test: process.env.REACT_APP_WISP_URL + "/elliotstest/",
    page_get: process.env.REACT_APP_WISP_URL + "/page/{page_id}/",
    page_docmaps_get: process.env.REACT_APP_WISP_URL + "/page/{page_id}/doc_maps/",
	page_docs_get: process.env.REACT_APP_WISP_URL + "/page/{page_id}/documents/",
    site_create: process.env.REACT_APP_WISP_URL + "/site/",
    site_get_by_url: process.env.REACT_APP_WISP_URL + "/site",
    site_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}",
    site_add_column: process.env.REACT_APP_WISP_URL + "/site/{site_id}/add_column",
    site_get_column: process.env.REACT_APP_WISP_URL + "/site/{site_id}/column",
	site_delete: process.env.REACT_APP_WISP_URL + "/site/{site_id}/delete",
    site_edit: process.env.REACT_APP_WISP_URL + "/site/{site_id}/",
    site_docMaps_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/doc_maps/",
    site_get_tickers: process.env.REACT_APP_WISP_URL +  "/site/{site_id}/stocks",
    site_locations_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/locations/",
    site_members_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/members/",
    site_pages_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/pages/",
    site_thumbnail_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/thumbnail/",
    site_views_get: process.env.REACT_APP_WISP_URL + "/site/{site_id}/views/",
    sitemap_create: process.env.REACT_APP_WISP_URL + "/sitemap/",
    sitemap_delete: process.env.REACT_APP_WISP_URL + "/sitemap/{sitemap_id}/delete/",
    sitemap_get: process.env.REACT_APP_WISP_URL + "/sitemap/{sitemap_id}/",
    sitemap_edit: process.env.REACT_APP_WISP_URL + "/sitemap/{sitemap_id}/",
    stock_create: process.env.REACT_APP_WISP_URL +  "/stock/create",
    stock_delete: process.env.REACT_APP_WISP_URL +  "/stock/delete",
    stock_get_data: process.env.REACT_APP_WISP_URL +  "/stock/get_data",
    stock_get_fair_value: process.env.REACT_APP_WISP_URL + "/stock/get_fair_value_price",
    stock_get_profile: process.env.REACT_APP_WISP_URL + "/stock/get_profile",
    stock_save_custom: process.env.REACT_APP_WISP_URL + "/stock/save_custom",
    user_azure_items: process.env.REACT_APP_WISP_URL + "/user/{user_id}/azure_items",
    user_bank_link: process.env.REACT_APP_WISP_URL + "/user/{user_id}/bank_link/",
    user_charges_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/charges/",
    user_delete: process.env.REACT_APP_WISP_URL + "/user/{user_id}/delete/",
    user_edit_algs: process.env.REACT_APP_WISP_URL + "/user/{user_id}/doc_algs/",
    user_follow: process.env.REACT_APP_WISP_URL + "/user/{user_id}/follow/",
    user_following: process.env.REACT_APP_WISP_URL + "/user/{user_id}/following/",
    user_followmaps_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/followmaps/",
    user_get_algs: process.env.REACT_APP_WISP_URL + "/user/{user_id}/doc_algs/",
    user_get: process.env.REACT_APP_WISP_URL + "/user/",
    user_link_stripe: process.env.REACT_APP_WISP_URL + "/user/{user_id}/link_stripe/",
    user_location_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/location/",
    user_set: process.env.REACT_APP_WISP_URL + "/user/{user_id}/",
    user_signout: process.env.REACT_APP_WISP_URL + "/user/{user_id}/signout/",
	user_sites_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/sites/",
	user_stats_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/stats",
    user_stripeInfo_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/stripe_info/",
    user_uids_get: process.env.REACT_APP_WISP_URL + "/user/{user_id}/uids/",
    user_unfollow: process.env.REACT_APP_WISP_URL + "/user/{user_id}/unfollow/",
}

export function getWispUrl(url, pathValue, pathValue2) {
    var i = url.indexOf('{');
    var i2 = url.indexOf('}');
    if (i > 0 && i2 > i) {
        var turl = url.substring(0,i) + pathValue + url.substring(i2+1);
        if (pathValue2) {
            i = turl.indexOf('{');
            i2 = turl.indexOf('}');
            if (i > 0 && i2 > i) {
                turl = turl.substring(0,i) + pathValue2 + turl.substring(i2+1);
            }
        }
        return turl;
    }    
    return "";
}

export function loadData(wispData, key, docId, doneFN, arg1, arg2) {
    if (wispData.eventData && wispData.eventData[key]) {
        doneFN(arg1, arg2);
    } else {
        var url = 'https://api.crazydoes.com/document/' + docId + '?format=cells';
        fetch(url, {method: 'GET', mode: 'cors'})
        .then(function(response) {
            return response.json();
        })
        .then(data => {
        var eData = wispData.eventData;
        eData[key] = data;
        wispData.setEventData(eData);
        if (doneFN) {
            doneFN(arg1, arg2);
        }
        })
        .catch((error) => {
            console.log('error = ' + error)
        });
    }
  }


/*
var url = 'https://api.wispbook.com/document/12345';
fetch(url, {method: 'GET', mode: 'cors'})
.then(function(response) {
    return response.json();
})
.then(data => {
    successFN(data);
})
.catch((error) => {
    errorFN(error);
});
*/
