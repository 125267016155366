import React, { useCallback, useContext, useEffect } from 'react';
import { WispContext } from './WispProvider';
import {WispButton} from './components/WispButton';
import { WispIconButton } from './components/WispIconButton';
import { WispNotification } from './components/WispNotification';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTable, faFile } from '@fortawesome/free-solid-svg-icons';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';
import { useDropzone } from 'react-dropzone';
import { takePicture, fileType, readVideoFile } from './util/document.js';
import { fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import {createSite, goToSite} from './util/site.js';
import $ from 'jquery';

export default function PortfolioCreate(props) {
    const [addContent, setAddContent] = React.useState(false);
    const [createOption, setCreateOption] = React.useState("blank");
	const [uploadCount, setUploadCount] = React.useState(0);
	const [alertDocMessage, setAlertDocMessage] = React.useState({text: '', type: 'warning'});

    var wispData = useContext(WispContext);

	var realTimeUploadCount = 0;		// can't count on uploadCont being up to date - gonna lag as crappy state stuff does...

	useEffect(() => {
        if (!props.wispData || !props.wispData.mobileView) {
            var w = $('body').width();
            var w2 = $("#decisionDiv").width();
            var l = w/2 - w2/2;
        }
        $("#decisionok").focus();
	}, []);

    // ONEDRIVE CODE
	function authOneDrive(prefix, site) {
		var redirectURI = process.env.REACT_APP_AZURE_REDIRECT_URI;
		window.location.href = 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?' +
			'client_id=' + '5d62e2c5-8548-4457-a100-973fd4894428' +
			'&response_type=code' +
			'&redirect_uri=' + redirectURI +
			'&response_mode=query' +
			'&scope=offline_access%20user.read%20files.read' +
			'&state=' + prefix + site.url
	}
    // END ONEDRIVE CODE 


	function MyDropzone() {
		const onDrop = useCallback(acceptedFiles => {
		  // Do something with the files
			acceptedFiles.forEach((file) => {
				uploadDoc(file)
		  });
		}, [])
		const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ' .xlsx'}) 
	  
		// , border: 'dashed', background: '#F0F0F0', padding: '5px'
		return (
			<div className='addItem'>
				<div {...getRootProps()}>
					<input {...getInputProps()} />
					{
					isDragActive ?
					<p>Drop the files here ...</p> :
                    <div 
                        className={`iconContainer`} 
                        onClick={() => changeOption("blank")}
                    >
                        <div className="optionIcon">
							<WispIconButton icon={faTable} id='spreadsheetBut' iconClass='addPortfolioIcon' />
						</div>
                        <div className="iconText">Upload Spreadsheet</div>
                    </div>
					}
				</div>
			</div>
		)
	}

	function doNothing() {

	}

	function getFileName(doc_id) {
		var docmaps = wispData.siteDocMaps;
		var filename = '';
		if (docmaps) {
			docmaps.forEach((docmap, i) => {
				if (docmap.doc_id === doc_id) {
					filename = docmap.filename;
				}
			});
		}
		return filename;
	}


	function uploadDoc(file) { // binaryStr, file) {
		console.log('uploadDoc');
        setCreateOption('spreadsheet');
        // need a site/portfolio to add this doc to
		var user = wispData.publicState.wispUser;
        createSite(wispData,  user.wisp_id, getSitePages, 'portfolio', file, 'invite');
	}

    function getSitePages(siteUrl, empty, site, file) {
        // need to get the site pages to add the doc to
        var url = getWispUrl(wisp_urls.site_pages_get, site.id);
        fetchGet(url, getPagesResult, wispData, false, file, null, null, site);
    }

    function getPagesResult(success, data, file, site) {
		if (success && site) {
			// put the site pages into the dictionary of site pages
			var curValue = wispData.sitePages;
			if (typeof curValue !== 'undefined') {
				curValue[site.id] = data;
			} else {
				// var id = props.site.id;
				curValue = { id: data };
			}
			wispData.setSitePages(curValue);
			var docMaps = data[0].doc_maps;	// data is encoded weird - not sure why we need [0] - i.e. have dictionary with array
			wispData.setSiteDocMaps(docMaps);	// not sure why we have this locally too - in any case, DocManager/SiteEdit use the wispData one

            // ok, can now upload the file per the selected mode
            var tCreateOption;
            setCreateOption(prevValue => {tCreateOption = prevValue; return (prevValue)});
            if (tCreateOption === 'spreadsheet') {
                uploadFile(file, site);
            } else if (tCreateOption === 'onedrive') {
                authOneDrive('picker_', site);
            }
		}
	}


	function uploadFile(file, site) {
		setAlertDocMessage({text: '', type: 'error'});
		realTimeUploadCount++;
		setUploadCount(realTimeUploadCount);
		// get the boxId from the first page
		var boxId = '';
		var pages = wispData.sitePages[site.id];
		if (pages) {
			var page = pages[0];
			var box = page.boxes[0];
			boxId = box.id;
		}
		var token = wispData.token;
		var headers = {
            'app-id':  process.env.REACT_APP_ID,
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
			'box-id': boxId,
			'position': 'last',
			'filename': file.name,
			'site-url': site.url,
			'source': 'file',
		}
		var url = wisp_urls.document_create;
		console.log('url = ' + url);
		const formData = new FormData();
		formData.append('file', file);
		fetch(url, { method: 'POST', headers: headers, body: formData })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				realTimeUploadCount--;
				var timeout = 0;
				console.log('file uploaded');
				setTimeout(function () { 
					setUploadCount(realTimeUploadCount);
					if ('detail' in data) {
						if (data['detail'] === 'exceeded storage') {
							setAlertDocMessage({text:"You've exceeded your 1 GB storage limit",type: 'error'});
						} else {
							setAlertDocMessage({text:"Failed to add the document(s) to this portfolio",type:'error'});
						}
					} else if (realTimeUploadCount === 0) {
						var user = wispData.publicState.wispUser;
                        // file is uploaded

                        props.fn1(props.p1, "spreadsheet", site, data);

					}
				}, timeout);
			})
			.catch((error) => {
				console.error('Error:', error);
				realTimeUploadCount--;
				setUploadCount(realTimeUploadCount);
			});		
	}


	function closeCreateOptions() {
		props.closeFN();
    }

    function changeOption(option) {
        setCreateOption(option);
        /* if (option === 'spreadsheet') {
            setAddContent(true);
        } else {
            setAddContent(false);
        } */
    }

    function uploadOneDrive() {
        setCreateOption('onedrive');
        // need a site/portfolio to add this doc to
		var user = wispData.publicState.wispUser;
        createSite(wispData,  user.wisp_id, getSitePages, 'portfolio', null, 'invite');

    }

    var alertDiv;
    if (alertDocMessage.text !== '') {
        alertDiv = (<WispNotification message={alertDocMessage.text} type={alertDocMessage.type} />);
	}

    return (
        <div>
            <div className= "createDivOverlay"></div>
            <div className="createDiv">
                <div className="createHeader">
                    Create New Portfolio
                    <div onClick={closeCreateOptions}>
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes} />
                    </div>
                </div>
                <div className="createOptionsContainer">
                    <div 
                        className={`iconContainer`} 
                        onClick={() => props.fn1(props.p1, "blank")}
                    >
                        <div className="optionIcon">
							<WispIconButton icon={faFile} id='spreadsheetBut' iconClass='addPortfolioIcon' />
						</div>
                        <div className="iconText">Blank</div>
                    </div>
                    <div>
            			<MyDropzone />
                    </div>
                    <div 
                        className='iconContainer'>
                        <div className="optionIcon">
                            <WispIconButton clickFN={uploadOneDrive} icon={faMicrosoft} id='onedriveBut' iconClass='addPortfolioIcon' />
                        </div>
                        <div className="iconText">One Drive</div>
                    </div>
                    <div>
                        
                    </div>

                </div>
                {/*<div className="createPortfolioButton">
                    {createOption != "" ? ( // Render button inactive if no option is selected
                        <WispButton
                            bcls='primaryButton'
                            d='inline-block'
                            width="200px"
                            js={() => props.fn1(props.p1, createOption)}
                            m='0px'
                            mes={props.c1} />
                    ) : (
                        <WispButton
                            bcls= 'inactiveButton'
                            d='inline-block'
                            width="200px"
                            m='0px'
                            mes={props.c1} />
                    )}
                </div> */}
            </div>
        </div>
    );

}
