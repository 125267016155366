import React, { useEffect } from 'react';
import { WispIconButton } from './components/WispIconButton';
import { WispNotification } from './components/WispNotification.js';
import { fetchGet, wisp_urls, getWispUrl} from './util/fetch.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faSpinner, faPen, faCaretDown } from '@fortawesome/free-solid-svg-icons'; // Import the trash icon
import InputPopup from './InputPopup.js';
import {wispHistory} from './components/WispHistory';


export default function PortfolioTable(props) {
	// var wispData = useContext(WispContext);
    const [tickers, setTickers] = React.useState([]);
    const [headerNames, setHeaderNames] = React.useState([]);
    const [showPopup, setShowPopup] = React.useState(false);
    const [showLoading, setShowLoading] = React.useState(false);
    const [loadingCount, setLoadingCount] = React.useState(0);
    const [warningMessage, setWarningMessage] = React.useState('')
    const [warningType, setWarningType] = React.useState('success')

	useEffect(() => {
        setHeaderNames(['Ticker', 'Price', 'Fair Value Price', 'Estimated Gain', 'Growth Estimate', 'Management Rating', 'Market Cap', 'Dividend Percentage', 'Cash', 'Debt', 'Number of Shares', 'Operating Income 2022', 'Operating Income 2023', 'Operating Income 2024', 'Operating Income 2025', 'Sector', 'Edit'])
        getTickers()
        getCustomCols()
	}, []);

    useEffect(() => {
        if (loadingCount === 0 && tickers.length > 0) {
            setShowLoading(false); // Hide loading when all data is fetched
        }
    }, [loadingCount]);

    function toggleAddPopup() {
        setShowPopup(!showPopup)
    }

    function getFairValuePrice(ticker) {
        var url = wisp_urls.stock_get_fair_value
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'site-id': props.site.id,
            'user-id': props.userId,
            'Ticker': ticker,
        }
        fetch(url, { method: 'GET', headers: headers})	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                updateFairValuePrice(ticker, data)
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function updateFairValuePrice(ticker, fairValuePrice) { // NECESSARY?

    }

    function addRow(inputValue) {
        setTickers(prevTickers => [...prevTickers, createEmptyRow(inputValue)]);
        setShowLoading(true);
        setLoadingCount(loadingCount + 1); // Increment the loading count
        getStockData(inputValue);
        setShowPopup(false);
    }
    
    function createEmptyRow(ticker) {
        let arr = Array(16).fill('').map((val, index) => (index === 0 ? ticker : val));
        return arr;
    }

    function addColumn(headerName) {
        if (headerName) {
            setHeaderNames(prevHeaders => [...prevHeaders, headerName])
        }
    }

    function alreadyPresent(ticker) { //Checks if a ticker is already in the table
        for (var i = 0; i < tickers.length; i++) {
            if (ticker == tickers[i][0]) {
                return true
            }
        }
        return false
    }

    function sortTable(index) {
        if (index == 16) {
            return;
        }
        const sortedTickers = [...tickers].sort((a, b) => {
            if (hasFloat(a[index])) {
                var float1 = parseFloat(cleanFloatString(a[index]));
                var float2 = parseFloat(cleanFloatString(b[index]));
                if (float1 > float2) {
                    return -1;
                } else {
                    return 1;
                }
            } else {
                if (a[index] < b[index]) {
                    return -1;
                } else {
                    return 1;
                }
            }
        });
        setTickers(sortedTickers)
    }

    function createRows(success, stockData) {
        var tickerInfo = stockData
        setTickers(tickerInfo)
        setLoadingCount(stockData.length)
        setShowLoading(true)
        for (var i = 0; i < stockData.length; i++) {
            getStockData(stockData[i][0])
        }
    }

    function getCustomCols() {
        var url = getWispUrl(wisp_urls.site_get_column, props.site.id);
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'user-id': props.userId,
        }
        fetch(url, { method: 'GET', headers: headers})	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                addColumn(data[0])
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function getStockData(ticker) {
        var url = wisp_urls.stock_get_data;
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'Ticker': ticker.toUpperCase(),
            'user-id': props.userId,
            'site-id': props.site.id,
        }
        fetch(url, { method: 'GET', headers: headers})	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                addStockData(data)
                setLoadingCount(prevCount => prevCount - 1);
                console.log('success: ' + data)
            })
            .catch((error) => {
                console.error('Error:', error);
                setLoadingCount(prevCount => prevCount - 1);
            });
    } 

    function addStockData(stockData) {
        setTickers(prevTickers => 
            prevTickers.map(ticker => 
                ticker[0] === stockData[0] ? stockData : ticker
            )
        );
    }    

    function deleteStock(ticker) {
        var url = wisp_urls.stock_delete;
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'site-id': props.site.id,
            'user-id': props.userId,
        }
        const stockData = {
            'ticker': ticker
        };
        fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(stockData) })	//  mode: 'cors', headers: headers,
            .then(function (response) {
                console.log('response.status = ' + response.status); // Will show you the status
                return response.json();
            })
            .then(data => {
                deleteRow(ticker)
                console.log('success = ' + data);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    function deleteRow(ticker) {
        setTickers(prevTickers => 
            prevTickers.filter(stock_data => stock_data[0] !== ticker)
        );
    }

    function getTickers() {
        var url = getWispUrl(wisp_urls.site_get_tickers, props.site.id);
        var token = props.token;
        var headers = {
            'Authorization': 'Bearer ' + token,
            'user-id': props.userId,
        }
        fetchGet(url, createRows, {header: headers})
    }

    function saveTicker(inputValue) {
        var tickers = inputValue.split(', ');
        var anySuccess = false;
        var failedTickers = [];
        
        // Collect all fetch promises in an array
        const promises = tickers.map(currTicker => {
            var ticker = currTicker.toUpperCase();
            if (!alreadyPresent(ticker) && ticker) {
                var url = wisp_urls.stock_create;
                var token = props.token;
                var headers = {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json',
                    'site-id': props.site.id,
                    'user-id': props.userId,
                };
                const stockData = {
                    'ticker': ticker
                };
                
                // Return the fetch promise
                return fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(stockData) })
                    .then(function (response) {
                        console.log('response.status = ' + response.status);
                        if (response.status === 404) {
                            failedTickers.push(ticker);
                            return false;
                        } else {
                            anySuccess = true;
                            addRow(ticker);
                            return true;
                        }
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        failedTickers.push(ticker);  // Consider as failed in case of error
                        return false;
                    });
            } else {
                // Return a resolved promise for already present or empty tickers
                return Promise.resolve(false);
            }
        });
    
        // Wait for all fetches to complete
        Promise.all(promises).then(() => {
            setShowPopup(false)
            if (failedTickers.length > 0) {
                if (anySuccess) {
                    setWarningMessage('Failed ticker: ' + failedTickers[0]);
                    setWarningType('warning');
                } else {
                    setWarningMessage('Failed ticker: ' + failedTickers[0]);
                    setWarningType('error');
                }
            } else {
                setWarningMessage('Success');
                setWarningType('success');
            }
    
            setTimeout(function () {
                setWarningMessage('');
            }, 3000);
        });
    }
    

    var addTickerStatusDiv;
    if (warningMessage) {
        addTickerStatusDiv = (
            <WispNotification message={warningMessage} type={warningType} />
        )
    }

    function cleanFloatString(str) {
        // Remove all characters except digits, period (.), and minus (-)
        return str.replace(/[^0-9.-]/g, '');
    }

    function hasFloat(str) {
        // Check if the string contains a valid float (optional - sign, digits, optional decimal point, and digits after)
        const floatRegex = /^-?\d+(\.\d+)?$/;
        const cleanedString = str.replace(/[^0-9.-]/g, ''); // Clean the string to remove symbols
        return floatRegex.test(cleanedString);
    }

    function headerStyle(index) { // Removes the pointer off of the last header
        if (index == 16) {
            return { cursor: 'default' }; // No pointer for index 4
        } else {
            return { cursor: 'pointer' }; // Pointer for other indices
        }
    }

    function showTableNav(ticker) {
        props.rowClickFn(ticker, tickers);
    }

    function editPortfolio() {
        console.log(props.site)
        wispHistory.push('/' + props.site.url + '/settings');
    }

    var tableHeader; // Can be utilized later to get data from the database
    tableHeader = (
        <thead>
            <tr>
            {headerNames.map((header, index) => ( // Creates headers for each item we want
                <th className="clickable" key={index} onClick={() => sortTable(index)}>
                    <span  style={headerStyle(index)}>
                        {header}
                        {/* <FontAwesomeIcon icon={faCaretDown} /> */}
                    </span>
                </th>
            ))}
            </tr>
        </thead>
    )

    var spinner = (
        <div className='wisp_app_link book_list_action'>
            <FontAwesomeIcon icon={faSpinner} className='fa-1x fa-spin' style={{ float: 'right', color: 'blue', marginTop: '3px', marginRight: '2px' }} />
        </div>
    )

    var tableBody;
    tableBody = (
        <tbody>
            {tickers.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {row.map((data, index) => ( // Creates headers for each item we want
                        <td key={index} onClick={() => showTableNav(row[0])}>
                            {data}
                            {index === 1 && showLoading && !data && spinner}
                        </td>
                    ))}
                    <td>
                        <FontAwesomeIcon icon={faTrash} onClick={() => deleteStock(row[0])} style={{cursor: 'pointer'}} />
                    </td>
                </tr>
                ))}
        </tbody>
    )

    return (
        <div className="tableContainer">
            {showPopup && <InputPopup onClose={toggleAddPopup} c1='Add Ticker' fn1={saveTicker} hint='Ticker' header='Add Ticker'/>}
            {addTickerStatusDiv}
            <div className="tableTitle">
                <div className="tableTitleText">
                    <div onClick={() => toggleAddPopup()} id='addTickerBut'>add ticker</div>
                </div>
                <div className="tableTitleText">
                    <div onClick={editPortfolio} icon={faPen} id='editPortBut' >settings</div>
                </div>
                {/* Move this to settings
                <div className="tableTitleText">
                    <WispButton
                        bcls='primaryButton'
                        width="200px"
                        js={() => addColumn()}
                        m='0px'
                        mes={"Add Column"} />
                </div> */}
            </div>
            <table className="styled-table">
                {tableHeader}
                {tableBody}
            </table>
        </div>
    );

}
