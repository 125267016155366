import React, { useContext, useEffect } from 'react';
// import {signinbox} from './util/base.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faCloudUploadAlt, faPlusCircle, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';
import { Footer } from './components/Footer';
import { WispContext } from './WispProvider.js';
import {WispButton} from './components/WispButton';
import {wispHistory} from './components/WispHistory.js';
import { AlertPopup } from './popups/AlertPopup.js';
import iconImage from './images/subpanelimg1.png';
import shotImage from './images/wordscreenshot.png';
import freeImage from './images/freetag.jpg';
// import $ from 'jquery';

export default function Home(props) {

  var wispData = useContext(WispContext);
  const [showAlert, setShowAlert] = React.useState(false);

  useEffect(() => {
		if (props.site) {
			if (props.site.access === 'subscription') { // only end up here if site not in authenticated site list - so either not signed in or signed in but don't have sitemap to this site
					console.log('home 2');
					wispData.showSubscribePopup(true);
					wispData.setSearchBookURL(props.site.url);
			} else if (props.site.access === 'invite') {
        if (wispData.publicState.validToken) {
          setShowAlert(true);
        } else {
          wispData.setShowSignin(true);
        }
      }
		}
    if (props.referral) {
      wispData.setReferral(props.referral);
    }

  }, [props.site]);

  function createAcct() {
    wispData.setShowCreateAccount(true);
  }

  function onPromo() {
    window.scrollTo(0,0);
    wispHistory.push('/referrals');
  }

  function closeAlert() {
    setShowAlert(false);
  }

  var story1 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Use Existing Content
        </div>
        <div className='eventSubAction'>
          Upload any MS Word document, Excel sheet, PDF document or YouTube video links from your computer or Microsoft OneDrive account. WispBook does the rest.
        </div>
      </div>
      <div className='bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '29px'}}>
          <img width='100px' src={iconImage} />
        </div>
      </div>
    </div>
  );

  var story2 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Everyone saves money
        </div>
        <div className='eventSubAction'>
          <div>
            WispBook is free for authors.
          </div>
          <div style={{marginTop: '12px'}}>
            Authors can make their books available for free or charge readers a small fee.
          </div>
        </div>
      </div>
      <div className='dollar_icon bottomPanel' style={{padding: '0px 5px 5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel'>
          <img width='300px' src={freeImage} style={{marginTop: '-20px'}} />
        </div>
      </div>
    </div>
  );

  var story3 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Use Familiar Tools
        </div>
        <div className='eventSubAction'>
        Create content using familiar tools including Microsoft Word & Excel. No need to learn new tools or editors.
        </div>
      </div>
      <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '25px'}}>
          <img width='250px' height='125px' src={shotImage} />
        </div>
      </div>
    </div>
  );

  var story4 = (
    <div className='crazyTile'>
      <div className='eventTextPanel'>
        <div className='eventAction'>
          Protect Your Investment
        </div>
        <div className='eventSubAction'>
          WispBook's copyright &amp; sharing protections help prevent unauthorized distribution of your course materials.
        </div>
      </div>
      <div className='story_icon_div bottomPanel' style={{padding: '5px', overflow: 'hidden'}}>
        <div className='innerBottomPanel' style={{paddingTop: '34px'}}>
          <FontAwesomeIcon icon={faCopyright} />
        </div>
      </div>
    </div>
  );

  var alertContent;
  if (showAlert) {
    alertContent = (
      <AlertPopup okFN={closeAlert} message={props.site.name + ' is invite only. Please contact the author to get access.'} />
    );
  }
  return (
      <div id="wrapper">
        <div className="panel1Cont">
          <div className="panel1aCont">
            <div className="panel1bCont">
              <div className='homeWispH1'>
                <div className='homeMaxH1Div'>Maximize stock gains</div>
                <div className='homeMaxH1SubDiv'>(and minimize sleepless nights)</div>
              </div>
              <div className='homeWispH4'>MaxBagger gives you AI-augmented fundamental analysis to find stocks that are under-valued today and likely to significantly increase in value over the next 2-3 years</div>
              <div className='startBufCont'>
                <WispButton
                  bcls='primaryButton'
                  d='inline-block'
                  width="300px"
                  js={createAcct}
                  m='0px'
                  mes="Find your first MaxBagger stock!" />
              </div>
            </div>
          </div>
        </div>

          {alertContent}
          <Footer/>

      </div>
  );
}
