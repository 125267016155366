import React, {useContext, useEffect} from 'react';
import {WispContext} from './WispProvider.js';
import { FilePicker } from './components/FilePicker';
import {getWispUrl, wisp_urls} from './util/fetch.js';
import {goToSite} from './util/site.js';
export const FilePickerView = (props) => {
    var wispData = useContext(WispContext);

    function doneFN(data) {
        // did we get a doc?
        if (data['doc']) {
            var user = wispData.publicState.wispUser;
            console.log('got doc = ' + data['doc']);
            var url = getWispUrl(wisp_urls.document_get_tickers, data['doc'].id);
			var token = wispData.token;
			var headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'site-id': props.site.id,
				'user-id': user.wisp_id,
            }
			fetch(url, { method: 'GET', headers: headers })	//  mode: 'cors', headers: headers,
                .then(function (response) {
                    console.log('response.status = ' + response.status); // Will show you the status
                    return response.json();
                })
                .then(data => {
                    console.log('success = ' + data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
			goToSite(props.site.url);
        } else {
            console.log('didnt get a doc');
        }

        var newurl = process.env.REACT_APP_PROTOCOL + '://www.' + process.env.REACT_APP_URL_POSTFIX + '/' + props.site.url;
        window.location.href = newurl;
    }

    return (
        <FilePicker site={props.site} itemName='Portfolio' doneFN={doneFN}/>
    )
}