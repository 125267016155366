import React, { useEffect } from 'react';
import {WispButton} from './components/WispButton';
import {WispInput} from './components/WispInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function InputPopup(props) {
	const [inputValue, setInputValue] = React.useState('');
	useEffect(() => {
        
	}, []);

    const handleInputChange = (value) => {
        setInputValue(value); // Set the inputValue state to whatever is typed in WispInput
    };

    return (
        <div>
            <div className= "createDivOverlay"></div>
            <div className="addDiv">
                <div className="createHeader">
                    {props.header}
                    <div onClick={() => props.onClose()}>
                        <FontAwesomeIcon className='fa-2x closeClass' icon={faTimes} />
                    </div>
                </div>
                <div className="addTickerContainer">
                    <WispInput
                        id={props.id}
                        hint={props.hint}
                        initValue=''
                        inputStyle={{paddingTop: '7px'}}
                        classes='active'
                        ti='1' 
                        onChange={handleInputChange} />
                </div>
                <div className="createPortfolioButton">
                    <WispButton
                        bcls='primaryButton'
                        d='inline-block'
                        width="200px"
                        js={() => props.fn1(inputValue)}
                        m='0px'
                        mes={props.c1} />
                </div>
            </div>
        </div>
    );

}
