import React, {useCallback, useContext, useEffect} from 'react';
import { WispContext } from './WispProvider';
import { WispNotification } from './components/WispNotification';
import { DocManager } from './components/DocManager';
import { SiteAuthorManager } from './components/SiteAuthorManager';
import { SiteProps } from './SiteProps';
import { SitePub } from './components/SitePub';
import { TableSettings } from './TableSettings';
import { fetchGet, getWispUrl, wisp_urls} from './util/fetch.js';
import { isAuthenticated } from './util/base.js';
import { goToSite } from './util/site.js';
import { ViewLog } from './components/ViewLog';
import InputPopup from './InputPopup.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';


export const BookSettings = (props) => {

	const [showPopup, setShowPopup] = React.useState(false);

	var wispData = useContext(WispContext);

	useEffect(() => {
		if (!props.new && isAuthenticated(wispData)) {
			var url = getWispUrl(wisp_urls.user_stripeInfo_get, wispData.publicState.wispUser.id);
			fetchGet(url, getStripeInfoResult, wispData);  
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps

	}, []);

    function getStripeInfoResult(success, data) {
        if (success) {
            wispData.setUserStripeInfo(data);
        }
    }

	function toggleAddPopup() {
        setShowPopup(!showPopup)
    }

	function returnTableView() {
		goToSite(props.site.url)
	}


	/* function vipDown() {

	} */



/*	function toggleTermsCond() {
//		props.site.agreed = !props.site.agreed;
		var token = wispData.publicState.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = getWispUrl(wisp_urls.site_edit, props.site.id);
		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(props.site) })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				setForceUpdate(forceUpdate + 1)
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}
*/
	/* function createBook() {
		var token = wispData.publicState.token;
		var headers = {
			'Content-Type': 'application/json',
			'Authorization': 'Bearer ' + token,
			'user-id': wispData.publicState.wispUser.id,
		}
		var url = wisp_urls.site_create
		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(props.site) })
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				console.log('success = ' + data);
				wispHistory.push('/');
			})
			.catch((error) => {
				console.error('Error:', error);
			});

	} */

	function runAlgorithm(doc_id) {
		var url = wisp_urls.elliots_test + doc_id + '/';
        fetchGet(url, printData, wispData); 
	}

	function printData(success, fData) {
		if (success) {
			alert(fData)
		}
	}

	var site = props.site;
	if (!site) {
		return(<div/>);
	}
	var userStripeInfo = wispData.publicState.userStripeInfo;

	var titleSection;
	var statusSection;
	var createButton;
	if (props.new) {
		titleSection = (
			<React.Fragment>
				<div className='panel_name' style={{marginLeft:'2px'}}>Create Your</div>
				<div className='panel_title' id='set_title'>New WispBook</div>
			</React.Fragment>
		);
	}
	else{
		titleSection = (
			<React.Fragment>
				<div className='panel_name' style={{marginLeft:'2px'}}>{site.name}</div>
				<div className='panel_title'>Settings</div>
			</React.Fragment>
		);
	}

	var viewManager;
	if (props.site.access === 'invite') {
		viewManager = (
			<SiteAuthorManager 
				site={props.site} 
				solo="You're the only viewer" 
				addMes='Add a viewer' 
				title='Viewers'
				desc='Viewers can view but not edit this book'
				hint="New Viewer's email address"
				duplicate="That person can already view this book"
				role="member"
				state='invited' />
		)
	}

	function addSiteColumn(colName) {
		var url = getWispUrl(wisp_urls.site_add_column, props.site.id);
		var token = props.token;
		var headers = {
			'Authorization': 'Bearer ' + token,
			'Content-Type': 'application/json',
			'user-id': wispData.publicState.wispUserId,
		}
		const colData = {
			'name': colName
		};
		fetch(url, { method: 'POST', headers: headers, body: JSON.stringify(colData) })	//  mode: 'cors', headers: headers,
			.then(function (response) {
				console.log('response.status = ' + response.status); // Will show you the status
				return response.json();
			})
			.then(data => {
				toggleAddPopup()
				console.log('success = ' + data);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	}
 
	return (
		<div className='bookSettingsContainer'>
			{showPopup && <InputPopup onClose={toggleAddPopup} c1='Add Column' hint='Column Name' header='Add Column' fn1={addSiteColumn}/>}
			<div onClick={() => returnTableView()} className='bookSettingsBackButton'>
				<FontAwesomeIcon icon={faChevronLeft} style={{paddingRight: 7.5}}/>
				Back
			</div>
			<div style={{marginLeft:'20px'}}>
				{titleSection}
			</div>			
			{statusSection}
			<SiteProps 
				new={props.new} 
				docMaps={props.docMaps} 
				site={props.site}
				siteName='WispBook'
				setAccess={true}
				prefix={true}
				setUrl
				/>
			{viewManager}
			<TableSettings
				new={props.new} 
				docMaps={props.docMaps} 
				site={props.site}
				fn1={toggleAddPopup}
				/>
            <SiteAuthorManager 
				site={props.site} 
				addMes='Add an analyst'
				title='Analysts'
				state='invited'
				desc='Analysts can edit the portfolio'
				duplicate='That person is already an analyst'
				solo="I'm the only analyst for this portfolio"
				hint='New Analyst Email'
			/>
            <ViewLog site={props.site} />
			{createButton}
		</div>
	);

	/*




	if(!newBook){
		var disp = 'display:none;';
		if(ts_teams[ts_nCurT + "_authorFee"] != '0')
			disp = '';
		<div id='set_banki_div' style={{marginTop:30px;position:relative;" + disp + "'>";
			<div className='set_sect_stat'>" + getStripeCheck() + "</div>
			<div className='wisp_app'><span style={{marginLeft:25px;'>Bank Information</span></div>
			<div className='wisp_app_list'>";
				<div id='set_stripeButDiv'></div>
			</div>
		</div>
	}

	// FILE MANAGEMENT


	// ADVANCED FEATURES
	<div style={{marginTop:30px;position:relative'}}>
		if(!newBook)
			<div className='set_sect_stat'>" + getStatCheck() + "</div>
		<div className='wisp_app'><span style={{marginLeft:25px;'>Advanced Features</span></div>
		<div className='wisp_app_list'>";
			str += drawTeamOptions(getApp(),"teamOpts");
			if(!newBook){
				<div id='setAPropsButDiv' style={{margin:20px 0px 0px 0px;position:relative;display:none'}}>
					<div className='webt_but webt_ui_back_2 webt_ui_color_1 wisp_in_mid' style={{padding:8px;fontSize:16px;width:200px;textAlign: center;cursor:pointer;margin:20px 0px 0px 0px;' onClick={setSiteProps(\"teamOpts\");'>Save</div>
				</div>
			}
		</div>
	</div>

	if(!newBook){
	}

	if(newBook){
		<div style={{margin:20px 0px 30px 0px;position:relative'}}>
			<div className='wisp_alert' id='createT_alert_div'>";
				<div className='arrow'></div>
				<p id='createT_alert_p'></p>";
			</div>
			<div className='webt_but webt_ui_back_2 webt_ui_color_1' id='set_create_but' style={{display:'inline-table',verticalAlign:middle;padding:8px;fontSize:16px;width:200px;textAlign: center;cursor:pointer;margin:20px 0px 0px 0px;' onClick={createTeam();'>Create " + getAppKeyword() + "</div>
			<div style={{display:'inline-table',verticalAlign:middle;padding:8px;fontSize:16px;width:100px;cursor:pointer;marginLeft:20px;marginTop:15px;' onClick={mainPanHome();'>cancel</div>
		</div>
	}
	*/
}
